import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'react-bootstrap'
import Layout from '../components/Layout/Layout'
import '../styles/pages/privacy.scss'

export default ({data}) => {
    return (
        <Layout>
          <Container className='content' fluid="xl" as='section'>
              <h1 className='page--heading'>{data.contentfulPolicy.title}</h1>
              <div className="privacy--content" dangerouslySetInnerHTML={{__html: data.contentfulPolicy.body.childMarkdownRemark.html}}/>
          </Container>
    </Layout>
    )
}

export const privacyPageQuery = graphql`
    query privacy {
        contentfulPolicy {
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
    }
`